import { each, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextArea from './TextArea';
import TextInput from './TextInput';
import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';

class CommentPopup extends Component {

  constructor(props) {
    super(props);

    const options = props.options || {};
    this.state = {
      commenter_name: options.commenter_name || '',
      commenter_email: options.commenter_email || '',
      text: '',
    };
  }

  componentDidMount() {
    /* if(!this.props.identity) {
      let client_name = window.localStorage.getItem('commonsku_client_name');
      let client_email = window.localStorage.getItem('commonsku_client_email');
      if (client_name) {
        this.setState({ commenter_name: client_name });
      }
      if (client_email) {
        this.setState({ commenter_email: client_email });
      }
    } */
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const options = nextProps.options || {};
    const state = this.state;

    const patch = {};
    each(['commenter_name', 'commenter_email'], (field) => {
      const value = options[field];
      if (value && value !== state[field]) {
        patch[field] = value;
      }
    });
    this.setState(patch);
  }

  handleFieldChange(field) {
    return ({ target }) => {
      if(field === 'commenter_name') {
        //window.localStorage.setItem('commonsku_client_name', target.value);
      }

      if(field === 'commenter_email') {
        //window.localStorage.setItem('commonsku_client_email', target.value);
      }

      this.setState({
        [field]: target.value,
      });
    };
  }

  render() {
    const { index, identity, options, closePopup  } = this.props;
    const { title, onSubmit } = options || {};
    const { commenter_name, commenter_email, text } = this.state;

    return <div className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}}
      aria-labelledby="modalTitle" aria-hidden="true" role="dialog"
    >
      <button className="close-button row" aria-label="Close modal" type="button"
        onClick={e => {
          e.preventDefault();
          closePopup();
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="row">
        <h3 className="small-12 columns">{ title || 'Comment' }</h3>
      </div>
      <div className="row popup-content">
        <div className="row">
          <div className="small-6 columns">
            <label style={{ fontSize: 18 }}>Name</label>
            {identity
              ? <div style={{ paddingBottom: 10, fontSize: 16 }}>{identity.user_first_name} {identity.user_last_name}</div>
              : <TextInput value={commenter_name} onChange={this.handleFieldChange('commenter_name')}/>
            }
          </div>
          <div className="small-6 columns">
            <label style={{ fontSize: 18 }}>Email</label>
            {identity
              ? <div style={{ paddingBottom: 10, fontSize: 16 }}>{identity.user_email}</div>
              : <TextInput value={commenter_email} onChange={this.handleFieldChange('commenter_email')}/>
            }
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <TextArea rows={8} value={text} onChange={this.handleFieldChange('text')}
              placeholder="Add any comment you have here"
            />
          </div>
        </div>
        <div className="small-1 offset-11 columns">
          <button className="button" onClick={() => {
            onSubmit(this.state).then(() => {
              closePopup();
            });
          }}>Done</button>
        </div>
      </div>
    </div>;
  }
}

const ConnectedCommentPopup = connect(
  (state) => {
    return {
      identity: state.identity,
    };
  },
  { closePopup, }
)(CommentPopup);

export default ConnectedCommentPopup;
